/* .signin-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .signin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .logo {
    display: flex;
    align-items: center;
    font-size: 24px;
  }
  
  .logo-text {
    background-color: white;
    color: #0199D9;
    padding: 5px;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .sign-up-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .signin-form-container {
    background-color: white;
    border-radius: 10px;
    padding: 50px 100px 50px 60px;
    max-width: 400px;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .signin-form-container h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    padding-left: 40px;
  }
  
  .input-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .signin-form-container 
  input[type='email'],
  .signin-form-container 
  input[type='password'] {
    width: 120%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-button {
    width: 130%;
    padding: 10px;
    background-color: #0199D9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
   */
   @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

   * {
       padding: 0px;
       margin: 0px;
       box-sizing: border-box;
   }
   
   :root {
       --linear-grad: #0066cc;
       --grad-clr1: #0066cc;
       --grad-clr2: #0066cc;
   }
   
   .login-page {
       height: 100vh;
       background: #f6f5f7;
       display: grid;
       place-content: center;
       font-family: 'Poppins', sans-serif;
   }
   
   



   .login-container{
    position: relative;
    width: 850px;
    height: 500px;
    background-color: #fff;
    box-shadow: 25px 30px 55px #5557;
    border-radius: 13px;
    overflow: hidden;
   }
   .login-form-container{
    position: absolute;
    width: 60%;
    height: 100%;
    padding: 0px 40px;
    transition: all 0.6s ease-in-out;

   }
   .login-sign-up-container{
    opacity: 0;
    z-index: 1;
   }
   .login-sign-in-container{
    z-index: 2;
   }
   .login-form{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
   }
   .login-heading{
    color: var(--grad-clr1);
   }
   .login-social-container{
    margin: 20px 0px;

   }
   .login-social-container a{
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    height: 40px;
    width: 40px;
   }
   .login-span{
    font-size: 12px;
   }
   .login-infield{
    position: relative;
    margin: 8px 0px;
    width: 100%;
    
   }
   input{
    padding: 12px 8px;
    background-color: #f3f3f3;
    border: none;
    outline: none;
   }
   /* label{
    position:absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    width: 0%;
    height: 2px;
    background: var(--linear-grad);
    transition: 0.3s;
   } */
   input:focus ~ label{
    width: 100%;
   }
   a{
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0px;

   }
   a.forgot{
    padding-bottom: 3px;
    border-bottom: 2px solid #EEE;
   }
   button{
    border-radius: 20px ;
    border: 1px solid var(--grad-clr1);
    background: var(--grad-clr2);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;

   }
   .login-form-container button{
    margin-top: 17px;
    transition: 80ms ease-in;

   }
   .login-form-container button:hover{
    background: #fff;
    color: var(--grad-clr1);
    
   }
   .login-overlay-container{
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 9;
  }
  #overlayBtn{
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 304px;
    transform: translateX(-50%);
    width: 143.67px;
    height: 40px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 20px;

  }
.login-overlay{
  position: relative;
  background: var(--linear-grad);
  color: #fff;
  left: -150%;
  height: 100%;
  width: 250%;
  transition: transform 0.6s ease-in-out;


}
.login-overlay-panel{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  text-align: center;
  height: 100%;
  width: 340px;
  transition: 0.6s ease-in-out;

}
.login-overlay-left{
  right: 60%;
  transform: translateX(-12%);

}
.login-overlay-right{
  right: 0;
  transform: translateX(0%);
}
.login-overlay-panel h1{
  color: #fff;

}
p{
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 25px 0px 35px;

}
.login-overlay-panel button{
  border: noen;
  background-color: transparent;
}

   