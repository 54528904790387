.search-bar-container {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #BABABA;
    height: 50px;
    padding: 0.5rem;
    border-radius: 25px;
    width: 500px; 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 500px;
  }
  .search-icon {
    color: #bbb;
    margin-right: 0.5rem;
    width:31.04px;
    height:31.04px;
  }
  .search-input {
    border: none;
    outline: none;
    font-weight: 500;
    margin-top: 15px;
    width: 100%;
    font-size: 25px;
    color: #666;
    background-color: transparent;
  }
  .search-input::placeholder {
    color: #bbb;
  }
  .blog-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10%;
    gap: 20px;
    z-index: 99;
  }
  
  .blog-card {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    height: 560px;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    z-index: 99;
    margin-bottom: 10px;
  }
  
  .blog-image {
    width: 100%; /* Make the image take the full width of the card */
    height: auto; /* Maintain the aspect ratio */
    object-fit: cover; /* Ensure it covers the area without distortion */
    z-index: 99;
    margin: 0; /* Remove margins to prevent overflow */
  }
  
  .blog-name h1{
    font-size: 1.2rem; /* Increased size for better visibility */
    font-weight: bold;
    color: #333;
    padding: 0 20px;
    z-index: 99;
  }
  
  .blog-detail {
    font-size: 1.1rem; /* Base size */
    padding: 0 20px 30px 20px;
    color: #333;
    z-index: 99;
    display: block; /* Ensure it's a block element */
    overflow: hidden; /* Hide any overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    height: 4.5em; /* Adjust height to fit three lines based on line height */
    line-height: 1.5; /* Adjust line height */
    margin: 0; /* Reset margin */
  }
  
  .learn-more-button {
    align-self: flex-start;
    margin-left: 5px; 
    margin-bottom: 20px;
    padding: 10px 20px;
    color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
  }
  
  .blog-card:hover {
    transform: scale(1.05);
  }
  
  .blog-card.rtl {
    direction: rtl; 
    text-align: right; 
  }

  .language-toggle {
    margin-bottom: 20px;
  }
  
  .language-toggle button {
    margin-right: 10px;
    padding: 10px 15px;
  }
  
  .language-toggle .active {
    background-color: #007bff; /* Change as needed */
    color: white;
  }
  .bloglist-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content-container {
    display: flex;
    flex: 1;
  }

  .stye-data h1{
    font-size: 1.2rem !important; 
  }
  
    

  @media (max-width: 1024px) {
    .blog-card {
        width: calc(50% - 20px); /* 2 cards per row for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .blog-card {
        width: 100%; /* 1 card per row for mobile */
        max-width: 300px; /* Limit the width for small screens */
    }
  
    .blog-image {
        width: 100%; /* Full width for mobile */
        height: auto; /* Maintain aspect ratio */
        object-fit: cover; /* Ensure it covers the area without distortion */
    }
  
    .blog-name {
        font-size: 1rem; /* Reduce font size for mobile */
        padding: 0 10px; /* Adjust padding */
    }
  
    .blog-detail {
        font-size: 0.9rem; /* Reduce font size for mobile */
        padding: 0 10px 20px 10px; /* Adjust padding */
    }
    
  }
  