
.signup-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(130deg, white 20%, #e3eeff 40%, #0199D9 100%);
  font-family: Arial, sans-serif;
}

.signup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.logo-text {
  background-color: white;
  color: #0199D9;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.sign-in-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.signup-form-container {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-group label {
  margin-left: 10px;
  font-size: 14px;
}

.create-account-button {
  width: 100%;
  padding: 10px;
  background-color: #0199D9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
