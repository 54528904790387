.blog-post {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    margin-bottom: 40px;
  }
  
  .title {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.2;
  }
  
  .highlight {
    color: #007bff;
  }
  
  .image-container {
    margin-bottom: 20px;
  }
  
  .header-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  
  .read-more {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .related-articles h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .article-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .article {
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .article img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
  
  .article p {
    padding: 10px;
    font-size: 14px;
    color: #333;
  }

  .related-blogs-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards */
  }
  
  .related-blog-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(33% - 20px); /* Adjust width as needed */
    display: flex;
    flex-direction: column;
  }
  
  .related-blog-image img {
    width: 100%;
    height: auto;
  }
  
  .related-blog-content {
    padding: 15px;
  }
  
  .related-blog-title {
    font-size: 18px;
    margin: 0 0 10px;
  }
  
  .related-blog-summary {
    font-size: 14px;
    color: #666;
    margin: 0 0 10px;
  }
  
  .learn-more-link {
    color: #007BFF;
    text-decoration: none;
  }
  
  .learn-more-link:hover {
    text-decoration: underline;
  }
  .related-blog-summary {
    font-size: 14px;
    color: #666;
    margin: 0 0 10px;
    display: block; /* Use block for layout */
    overflow: hidden; /* Hide overflow text */
    max-height: 4.5em; /* Limit height to approximately three lines */
    line-height: 1.5; /* Set line height for calculations */
  }
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }

  .blog-post.rtl {
    direction: rtl; 
    text-align: right; 
  }
  
  .related-blog-card.rtl {
    direction: rtl;
    text-align: right; 
  }
  .app-container {
    display: flex;
  }
  
  .main-layout {
    flex: 1;
  
  }
  
  
li[data-list="bullet"]{
  list-style:disc;
}
li[data-list="ordered"]{
  list-style:decimal;
}


  @media (max-width: 768px) {
    .article-grid {
      grid-template-columns: 1fr;
    }
  }